import styled from 'styled-components';

export const Content = styled.div`
  opacity: 1;
`;

export const Paper = styled.div`
  position: relative;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
`;
